@import "dash";


.wrapper {
    max-width: calc(960px - (30px * 2));
}

.highlight {
    font-weight: 500;
}

body {
    font-size: 20px;
}

p {
    margin: 1em 0;
}

.site-title {
    margin-left: 10px;
    margin-right: 10px;
}

// tab genişliği 4 boşluk
pre {
    tab-size: 4;
}

// daha güzel bir hr
hr {
    border: none;
    border-top: 1px solid $color-green;
    border-color: $color-green;
}

// uzun kod bloğunu böl
code.highlighter-rouge {
    word-break: break-word;
}

li > ul, li > ol {
    margin-left: 1.5em !important;
}

h1 > code, h2 > code, h3 > code, h4 > code, h5 > code, h6 > code {
    font-size: unset
}
